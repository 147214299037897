<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-list flat>
              <h3>Dettagli del Prodotto</h3>
              <ListItem subtitle="Marca">
                {{ product.brand }}
              </ListItem>
              <ListItem subtitle="Modello">
                {{ product.model }}
              </ListItem>

              <ListItem subtitle="Seriale">
                {{ product.part_name }}
              </ListItem>
              <ListItem subtitle="Attivo">
                {{ product.active }}
              </ListItem>
              <ListItem subtitle="Descrizione">
                {{ product.description }}
              </ListItem>
            </v-list>
          </v-col>
          <v-col cols="3">
            <v-list flat>
              <ListItem subtitle="Costo Hardware">
                {{ product.cost_hardware }}
              </ListItem>
              <ListItem subtitle="Costo Software">
                {{ product.cost_software }}
              </ListItem>

              <ListItem subtitle="Costo installazione Tecnico">
                {{ product.cost_setup_base }}
              </ListItem>
              <ListItem subtitle="Altri costi installazione">
                {{ product.cost_setup_additional }}
              </ListItem>
              <ListItem subtitle="Costo Manutenzione">
                {{ product.cost_maintenance }}
              </ListItem>
            </v-list>
          </v-col>
          <v-col cols="3">
            <v-list flat>
              <ListItem subtitle="Costo Gestione">
                {{ product.cost_management }}
              </ListItem>
              <ListItem subtitle="Costo Trasporto">
                {{ product.cost_shipping }}
              </ListItem>

              <ListItem subtitle="Costo Assicurazione">
                {{ product.cost_insurance }}
              </ListItem>
              <ListItem subtitle="Costo Accessori">
                {{ product.cost_additional }}
              </ListItem>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import ListItem from '@components/structure/lists/ListItem.vue'

export default {
  name: 'ProductDetails',
  components: {
    ListItem,
  },
  computed: {
    ...authComputed,
    ...mapState('products', {
      product: 'current',
    }),
  },
  mounted() {
    this.setCurrent(this.$route.params.id)
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapActions('products', ['setCurrent']),
    ...mapMutations('products', {
      reset: 'RESET_CURRENT',
    }),
  },
}
</script>

<style scoped>
</style>
